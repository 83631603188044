* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
   background: rgb(255, 46, 0);
}

#list {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  font-weight: bold;
}

input {
  background: rgba(255, 0, 255, 0.4)
}

label {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#container-btn {
  display: flex;
  justify-content: center;
}

p {
  text-align: center;
}