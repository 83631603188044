* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #ff2e00;
}

#list {
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  display: flex;
  overflow: scroll;
}

input {
  background: #f0f6;
}

label {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

#container-btn {
  justify-content: center;
  display: flex;
}

p {
  text-align: center;
}

/*# sourceMappingURL=index.8105eb5b.css.map */
